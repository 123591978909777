import React from "react"
import { Box } from "@chakra-ui/core"
import Slider from "react-slick"
import styled from "@emotion/styled"
import { ChevronLeft } from "@emotion-icons/boxicons-regular/ChevronLeft"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledBox = styled(Box)`
  .slick-arrow::before {
    content: "";
  }
  .slick-slide .bannerSlide {
    padding: 0.8em;
    text-align: center;
  }
`

const LeftArrow = ({ color, onClick }): JSX.Element => {
  return (
    <Box cursor="pointer" onClick={onClick}>
      <ChevronLeft size="2.8rem" color={color} />
    </Box>
  )
}

const RightArrow = (props): JSX.Element => {
  return (
    <Box transform="rotate(180deg)">
      <LeftArrow {...props} />
    </Box>
  )
}

interface VideoSliderProps {
  arrowColor?: string
}

const VideoSlider = (props: VideoSliderProps) => {
  const settings = {
    autoplay: true,
    speed: 800,
    autoplaySpeed: 3000,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: (
      <Box
        position="absolute"
        top="50%"
        right={["5%", 0]}
        transform="translate(250%, -150%)"
      >
        <RightArrow color={props.arrowColor ?? "#000"} />
      </Box>
    ),
    prevArrow: (
      <Box
        position="absolute"
        top="50%"
        left={["5%", 0]}
        transform="translate(-250%, -150%)"
      >
        <LeftArrow color={props.arrowColor ?? "#000"} onClick={() => null} />
      </Box>
    ),
  }

  const data = useStaticQuery(graphql`
    query {
      banner1: file(relativePath: { eq: "banner1.jpg" }) {
        childImageSharp {
          fixed(width: 250, height: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      banner2: file(relativePath: { eq: "banner2.jpg" }) {
        childImageSharp {
          fixed(width: 250, height: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      banner3: file(relativePath: { eq: "banner3.jpg" }) {
        childImageSharp {
          fixed(width: 250, height: 150) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const slides = [
    data.banner1.childImageSharp.fixed,
    data.banner2.childImageSharp.fixed,
    data.banner3.childImageSharp.fixed,
    data.banner1.childImageSharp.fixed,
    data.banner2.childImageSharp.fixed,
    data.banner3.childImageSharp.fixed,
  ]

  return (
    <StyledBox>
      <Box
        position="relative"
        maxW={["300px", "600px", null, null, "850px"]}
        mx="auto"
        py={10}
      >
        <Slider {...settings}>
          {slides.map((img, index) => (
            <Box className="bannerSlide" key={index}>
              <Img fixed={img} />
            </Box>
          ))}
        </Slider>
      </Box>
    </StyledBox>
  )
}
export default VideoSlider
